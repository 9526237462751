import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from 'utils/bigNumber'
import { filterFarmsByQuoteToken } from 'utils/farmsPriceHelpers'
import { Prices, SerializedFarm } from 'state/types'
import tokens from 'config/constants/tokens'
import { getMtvPrice } from './prices'


const getFarmFromTokenSymbol = (
  farms: SerializedFarm[],
  tokenSymbol: string,
  preferredQuoteTokens?: string[],
): SerializedFarm => {
  const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol)
  const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
  return filteredFarm
}


const getFarmBaseTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  bnbPriceBusd: BigNumber,

  moon: BigNumber
): BigNumber => {
  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

  const stableTokens = ["USDT", "USDC", "DAI", "MAI", "BUSD"]

  if (farm.token.symbol === 'MOON') {
    return moon
  }
  // if (farm.token.symbol === 'RELAY') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['relay'].price)
  // }
  // if (farm.token.symbol === 'TOOLS') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['tools'].price)
  // }
  // if (farm.token.symbol === 'FTM') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['ftm'].price)
  // }
  // if (farm.token.symbol === 'AVAX') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['avax'].price)
  // }
  // if (farm.token.symbol === 'MATIC') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['matic'].price)
  // }
  // if (farm.token.symbol === 'BNB') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['bnb'].price)
  // }
  // if (farm.token.symbol === 'WETH') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['eth'].price)
  // }
  // if (farm.token.symbol === 'SDN') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['sdn'].price)
  // }
  // if (farm.token.symbol === 'BEANS') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['beans'].price)
  // }
  // if (farm.token.symbol === 'RIVRDOGE') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['rivrdoge'].price)
  // }

  if (farm.token.symbol === 'MTV') {
    /* eslint-disable */
    return new BigNumber(bnbPriceBusd)
  }

  if (stableTokens.includes(farm.token.symbol)) {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === tokens.busd.symbol) {
    return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (farm.quoteToken.symbol === tokens.weth.symbol) {
    return hasTokenPriceVsQuote ? bnbPriceBusd.times(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  // We can only calculate profits without a quoteTokenFarm for BUSD/BNB farms
  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
  // If the farm's quote token isn't BUSD or wmtv, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - BNB, (pBTC - BNB)
  // from the BNB - pBTC price, we can calculate the PNT - BUSD price
  if (quoteTokenFarm.quoteToken.symbol === tokens.weth.symbol) {
    const quoteTokenInBusd = bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote)
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === tokens.busd.symbol) {
    const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  // Catch in case token does not have immediate or once-removed BUSD/wmtv quoteToken
  return BIG_ZERO
}

// export const fetchPrices = async () => {
//   const responseAPI = await fetch("https://api.moonswap.tech/api/prices")
//   const PRICES = await responseAPI.json();
//   return PRICES
// }

const getFarmQuoteTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  bnbPriceBusd: BigNumber,

  moon: BigNumber,
): BigNumber => {
  

  if (farm.quoteToken.symbol === 'MOON') {
    return moon
  }

  if (farm.quoteToken.symbol === 'SDN') {
    return bnbPriceBusd
  }

  if (farm.quoteToken.symbol === 'USDT') {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'WMTV') {
    /* eslint-disable */
    return new BigNumber(bnbPriceBusd)
  }
  
  if (farm.quoteToken.symbol === 'MTV') {
    /* eslint-disable */
    return new BigNumber(bnbPriceBusd)
  }

  // if (farm.quoteToken.symbol === 'MOVR') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['movr'].price)
  // }

  // if (farm.quoteToken.symbol === 'MATIC') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['matic'].price)
  // }

  // if (farm.quoteToken.symbol === 'FTM') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['ftm'].price)
  // }

  // if (farm.quoteToken.symbol === 'AVAX') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['avax'].price)
  // }

  // if (farm.quoteToken.symbol === 'RIVRDOGE') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['rivrdoge'].price)
  // }

  // if (farm.quoteToken.symbol === 'FTM') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['ftm'].price)
  // }

  if (farm.quoteToken.symbol === 'BUSD') {
    return BIG_ONE
  }
  if (farm.quoteToken.symbol === 'USDC') {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'WMTV') {
    return bnbPriceBusd
  }
  if (farm.quoteToken.symbol === 'MOVR') {
    return bnbPriceBusd
  }
  // if (farm.quoteToken.symbol === 'BEANS') {
  //   /* eslint-disable */
  //   return new BigNumber(prices['beans'].price)
  // }

  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'WMTV') {
    return quoteTokenFarm.tokenPriceVsQuote ? bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'BUSD') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }
  return BIG_ZERO
}

const fetchFarmsPrices = async (farms: SerializedFarm[]) => {
  // const prices = await fetchPrices()
  const mtvPrice = await getMtvPrice()
  const bnbPriceBusd = new BigNumber(mtvPrice);

  const boltSdnFarm = farms.find((farm) => farm.pid === 1)
  const boltPrice = new BigNumber(boltSdnFarm.lpTotalInQuoteToken).div(2).div(boltSdnFarm.tokenAmountTotal).times(mtvPrice)
  const farmsWithPrices = farms.map((farm) => {

    const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol)
    const tokenPriceBusd = getFarmBaseTokenPrice(farm, quoteTokenFarm, bnbPriceBusd,  boltPrice)
    const quoteTokenPriceBusd = getFarmQuoteTokenPrice(farm, quoteTokenFarm, bnbPriceBusd,  boltPrice)

    return {
      ...farm,
      tokenPriceBusd: tokenPriceBusd.toJSON(),
      quoteTokenPriceBusd: quoteTokenPriceBusd.toJSON(),
    }
  })


  return farmsWithPrices
}

export default fetchFarmsPrices
