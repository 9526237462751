import React, { useState } from 'react'
import { MaxUint256 } from '@ethersproject/constants'
import { getMoonspaceworld, getMoonSwapToken } from 'utils/addressHelpers'
import { useMoonSwapContract, useMoonSpaceWorld } from 'hooks/useContract'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import SearchInput from 'components/SearchInput'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components'
import { AppHeader, AppBody } from 'components/App'
import { ethers } from 'ethers'
import { Button, Text, Flex, useModal } from 'mtv-mswap-uikitty'
import Loading from 'components/Loading'
import useToast from 'hooks/useToast'
import { useBusdPriceFromPid } from 'state/farms/hooks'
import { BigNumber } from 'bignumber.js'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import DepositModal from 'views/Staking/Components/DepositModal'
import WithdrawModal from 'views/Staking/Components/WithdrawModal'
import { useIsApproved, useMoonAPR, useMoonTVL, useMoonUserBalance, useMoonXMoonRatio, useStakedMoon, useXMoonUserBalance } from 'state/staking/hooks'
import Page from '../Page'



/* eslint-disable */


const Staking: React.FC = () => {

    // This config is required for number formatting
    BigNumber.config({
        EXPONENTIAL_AT: 1000,
        DECIMAL_PLACES: 80,
    })
    const { account } = useActiveWeb3React()


    //CUSTOM HOOKS
    const tvl = useMoonTVL()    
    const apr = useMoonAPR()
    const totalUserMoonBalance = useMoonUserBalance()
    const totalUserXMoonBalance = useXMoonUserBalance()
    const moonBalance = useStakedMoon()
    const moonXmoonRatio = useMoonXMoonRatio()
    const moonPrice = useBusdPriceFromPid(1)    
    const isApproved = useIsApproved()    

    const moonSwapContract = useMoonSwapContract()
    const moonSpaceWorldContract = useMoonSpaceWorld()    
    const moonSpaceWorldAddress = getMoonspaceworld()    

    const [loading, setLoading] = useState(false)
    const { toastSuccess, toastError } = useToast()

    const handleStake = async (amount: string) => {        
        const stakeAmount = ethers.utils.parseEther(amount)                
        const tx = await moonSpaceWorldContract.enter(stakeAmount, { gasPrice: ethers.utils.parseUnits('3', 'gwei') })                
        const receipt = await tx.wait()
        window.location.reload()
      }

    const handleUnstake = async (amount: string) => {        
        const stakeAmount = ethers.utils.parseEther(amount)                
        const tx = await moonSpaceWorldContract.leave(stakeAmount)                      
        const receipt = await tx.wait()
        window.location.reload()
      }

      const approve = async () => {        
        setLoading(true)
        try {
            const approve = await moonSwapContract.approve(moonSpaceWorldAddress, MaxUint256)
            const waitApproval = await approve.wait()
            toastSuccess('Success', 'You have succesfully approved xMoon staking')
            window.location.reload()
        } catch (error) {
            toastError('Error', 'Please try again. Confirm the transaction and make sure you are paying enough gas!')
        }
        setLoading(false)             
      }

    const [onPresentDeposit] = useModal(
        <DepositModal
          max={totalUserMoonBalance}
          stakedBalance={moonBalance}
          onConfirm={handleStake}
          tokenName={"MOON"}
          multiplier={"60x"}
          lpPrice={BIG_ZERO}
          lpLabel={"MOON-XMOON"}
          apr={apr}
          displayApr={"true"}
          addLiquidityUrl={"https://mtv.moonswap.tech//swap?outputCurrency=0x0fed6177c230a6091e58d0081dcd31f88a256185"}
          cakePrice={moonPrice}        
        />,
      )

      const [onPresentWithdrawal] = useModal(
        <WithdrawModal
          max={totalUserXMoonBalance}
          stakedBalance={moonBalance}
          onConfirm={handleUnstake}
          tokenName={"XMOON"}
          multiplier={"60x"}
          lpPrice={BIG_ZERO}
          lpLabel={"MOON-XMOON"}
          apr={apr}
          displayApr={"true"}
          addLiquidityUrl={"https://mtv.moonswap.tech//swap?outputCurrency=0x0fed6177c230a6091e58d0081dcd31f88a256185"}
          cakePrice={moonPrice}        
        />,
      )    

    return (
        <div className="staking-container">
            <AppBody>
                {loading ? (
                    <div className="loading-indicator migration">
                        <span>Approving</span>
                        <div style={{ marginTop: "28px", marginLeft: "10px" }}>
                            <Loading />
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <Flex className="staking-wrapper" style={{ justifyContent: 'center' }}>
                    <div className="staking-description">
                        <img className="staking-logo" src="/images/moonswap-staking.png" width="180px" />
                        <div className="staking-info">
                            Stake MOON here and receive xMOON as receipt representing your share of the pool. This pool automatically compounds by using a portion of all trade fees to buy back MOON which means the xMOON to MOON ratio will grow over time!
                        </div>
                    </div>
                    <div style={{ width: 150, height: 125, marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
                        <Flex style={{ whiteSpace: 'nowrap', marginLeft: -15 }}>
                            <Text style={{ textAlign: 'center', paddingBottom: 10, paddingRight: 5 }}>
                                1 xMOON
                            </Text>
                            <Text style={{ textAlign: 'center', paddingBottom: 10, color: '#0447FC', fontWeight: 'bold' }}>
                                = {moonXmoonRatio ? moonXmoonRatio.toFixed(4) : 0} MOON
                            </Text>
                        </Flex>
                        <div className="staking-ratio">
                            <CircularProgressbar value={moonXmoonRatio ? (105 - (moonXmoonRatio * 100)) : 0}
                                styles={buildStyles({
                                    rotation: 0.25,
                                    strokeLinecap: 'round',
                                    textSize: '12px',
                                    pathTransitionDuration: 2,
                                    pathColor: `rgba(4 204 189, ${1})`,
                                    textColor: '#D22C89',
                                    trailColor: '#0447FC',
                                    backgroundColor: '#3e98c7',
                                })}
                            />
                        </div>
                        <div className="staking-information">
                            <Flex style={{ justifyContent: 'space-between', width: 250, marginLeft: -50, marginTop: 20 }}>
                                <Text>Claimable in $</Text>
                                <Text>${moonBalance ? (moonBalance.toNumber() * moonPrice.toNumber()).toLocaleString('en-US') : "0"}</Text>
                            </Flex>
                            <Flex style={{ justifyContent: 'space-between', width: 250, marginLeft: -50, marginTop: 20 }}>
                                <Text>Staked MOON</Text>
                                <Text>
                                    {moonBalance ? moonBalance.toFixed(4) : 0}
                                    <img alt="" src="/images/boltswap-token.png" width="20px" style={{ marginLeft: 5, display: 'inline-block', marginBottom: -5 }} />
                                </Text>
                            </Flex>
                            <Flex style={{ justifyContent: 'space-between', width: 250, marginLeft: -50, marginTop: 20 }}>
                                <Text>TVL</Text>
                                <Text>${tvl ? tvl.toLocaleString('en-US') : "0"}</Text>
                            </Flex>
                            <Flex style={{ justifyContent: 'space-between', width: 250, marginLeft: -50, marginTop: 20 }}>
                                <Text>APR</Text>
                                <Text>{apr ? apr.toLocaleString('en-US') : "0"}%</Text>
                            </Flex>
                        </div>
                        {isApproved != "0" ?
                            <div>
                                <Flex style={{ justifyContent: 'center', width: 250, marginLeft: -50, marginTop: 20, marginBottom: 10, borderTop: '2px solid #0447FC', paddingTop: 8}}>
                                    <Text style={{ fontSize: 14, paddingRight: 6 }}>Balance:</Text>
                                    <Text style={{ fontSize: 14, fontWeight: 'bold' }}> {totalUserXMoonBalance ? totalUserXMoonBalance.toNumber().toFixed(3) : 0} xMOON</Text>
                                    <img src="/images/xmoon-token-mtv.png" width="30px" alt="" style={{marginLeft: 8, marginTop: -4}} />
                                </Flex>
                                <Flex style={{ justifyContent: 'center', gap: 15, marginTop: 20 }}>
                                    <Button className="staking-button" onClick={onPresentWithdrawal} style={{border: '1px solid #0447FC', backgroundColor: 'transparent'}}>-</Button>
                                    <Button className="staking-button" onClick={onPresentDeposit} style={{border: '1px solid #0447FC', backgroundColor: 'transparent'}}>+</Button>                                
                                </Flex>
                            </div>
                            :
                            <Button style={{display: 'block', marginTop: 20, marginLeft: 'auto', marginRight: 'auto'}} onClick={approve} >
                                Approve
                            </Button>
                        }                        
                    </div>
                </Flex>
            </AppBody>
        </div>
    )

}

const Wrapper = styled.div`
display: flex;
flex-direction: row;
`

export default Staking