import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'moonswap-uikitty/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`  
  body {
    background-color: rgb(8, 6, 11);
    background: radial-gradient(circle,#0447FC -150%,rgb(4,4,4) 100%);

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .page-section-second {
    div:hover {
      transform: scale(1.02);
      transition: 0.5s all;
    }
  }

  .loading-indicator {
    width: 100%;
    position: absolute;
    height: inherit;
    justify-content: center;
    display: flex;
    background-color: #202020ed;    
    
    span {
      color: #FFFFFF;
      font-size: 25px;
      font-weight: bold;
      display: block;
      text-align: center;
      margin-top: 25px;
      cursor: default;
    }

    &.migration {
      top: 0px;
      z-index:999;

      span {
        margin-top: auto;
        margin-bottom: auto;
      }

      div {
        margin-top: auto !important;
        margin-bottom: auto;
      }
    }
  }

  .bridge-coming-soon {
    height: 2600px;
    position: relative;

    .coming-soon-text {
      position: absolute;
      color: #D22C89;
      left: 0; 
      right: 0; 
      text-align: center;
      margin-left: auto; 
      margin-right: auto; 
      font-size: 40px;
      font-weight: bold;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .staking-info {
    background-color: #0447fc;
    padding: 15px;
    border-radius: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .staking-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .footer-logo {
    .header-icon {
      width: 170px;
      height: auto;
    }
  }

  .socials-icon {
    width: 25px;
    height: 25px;

    &:nth-child(1) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .migration-dropdown {
    max-width: 100px;
    *{
      font-weight: bold;
    }
  }

  .migration-header {    
  }

  .migration-amount {
    max-width: 165px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;    
    margin-bottom: 20px;
    
    *{
      font-weight: bold;
    }
  }

  .heading-style {
    margin-bottom: 3px;
    padding: 6px;
    background: #0447fc;
    border-radius: 16px;
    text-align: center;
    font-size: 18px;
  }



  .search-locker {
    justify-content: center;
    position: relative;
    padding-left: 16px;
    padding-right: 35px;
    margin-bottom: 20px;
    max-width: 650px;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    border-radius: 16px;
    margin-left: auto;
    background: #030918;
    height: 60px;
    border: 1px solid #372F47;
    color: #F4EEFF;
    text-overflow: ellipsis;
    -webkit-appearance: textfield;
    *{
      font-weight: bold;
    }

    
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 1px #0447fc, 0px 0px 0px 4px #0447fc;
    }

    ::placeholder {
    color: ${({ theme }) => theme.colors.textSubtle};
  }

  }



  .search-locker-tab {
    justify-content: center;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-width: 950px;
    width: 100%;
    
    *{
      font-weight: bold;
    }
  }

  .date-time {
  

    max-width: 650px;
    width: 100%;
    text-overflow: ellipsis;
    font-weight: bold;
    background: #030918;
    
    height: 60px;
    outline: 0;
    border-radius: 0.25rem;
    font-size: 16px;
    padding: 0 16px;
    box-shadow: inset 0px 2px 2px -1px rgb(74 74 104 / 10%);
    z-index: 999 !important;
    color: #F4EEFF;
    border-radius: 16px;
    margin-left: auto;
    border: none;

    &:focus {
      box-shadow: 0px 0px 0px 1px #0447fc, 0px 0px 0px 4px #0447fc;
    }
  }
  
  .search-button {
    width: 100%;
    max-width: 950px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;    
    margin-bottom: 20px;
  }

  .lock-button {
    max-width: 650px;
    width: 100%;
    justify-content: center;


  }

  .staking-amount {
    max-width: 165px;
    min-width: 137px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;    
    margin-bottom: 20px;
    margin-top: 15px;
    font-size: 14px;
    
    *{
      font-weight: bold;
      font-size: 14px;
      text-align: center;
    }
  }

  .staking-description {
    width: 50%;
  }

  .staking-ratio {
    background: url(images/xmoon-token-mtv.png);
    background-repeat: no-repeat;
    background-size: 135px 135px;
    background-position: center;
    
  }

  .cosZie {
    margin-left: auto;
    margin-right: auto;
  }

  .jLTlRF {
    flex-direction: row;
  }

  .hBgRus, .hdlbqC, .hBeiVu, .liNYvh, .fEXhkl, .gXaxaI, .gjHitL, .bmLmy {
    background-color: #053b58;
  }

  .bfeveF, .cDMaTH {
    background-color: #0447FC;
  }

  .staking-button {
    width: 58px;
    font-size: 25px;
  }

  .approve-button {
    width: 150px;
    margin-top: 20px;
  }

  .migration-button {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .dKQEfU {
    margin-left: auto;
    margin-right: auto;
  }

  .fepHIR, .kPqiJB {
    border-bottom: none;
  }

  .socials {
    align-items: center;
    justify-content: center;
  }

  .docs {
    order: 3;
    color: white;
    text-align: center;
  }

  .tvl-string {
    color: #04E4D4;
    display: block;
    font-size: 25px;
    font-weight: bold;
    margin-left: auto;
    
    .title-tvl {
      color: #0447FC;
      padding-right: 7px;
    }
  }

  .my-holdings-string {
    color: #04E4D4;
    display: block;
    font-size: 25px;
    font-weight: bold;
    position: absolute;
    top: 125px;
    .title-tvl {
      color: #0447FC;
      padding-right: 7px;
    }
  }

  .harvest-card {
    .tvl-string {      
      display: block;
      margin-left: auto;
      text-align: right;
      margin-bottom: 16px;
    }
    .harvest-all {
      margin-left: auto;
    }
  }

  /* .hiOtqn *{
    font-family: Roboto;  
  } */

  .kpWzIH {
    font-size: 15.7px;
  }

  .imiWnV {
    margin-left: auto;
    margin-right: auto;
  }

  .bqVoIi {
    display: none;
  }


  .bPepfS {
    grid-template: initial;
  }



  .kkrQbD {
    align-items: center;
  }

  .kGFlVV {
    display: none;
  }

  .kaRICp, .EHRxJ, .lRfdj {
    &:before {
      border: none;
    }
  }

  // .KmhKu {
  //   margin-left: auto;
  //   margin-right: auto;
  // }

  .homepage-card {
    * {
      text-align: center;
    }
    div[width="fit-content"] {
      max-width: 100% !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .hCTQSm {
    text-align: center;
  }

  .jiWrzP {
    margin-left: auto;
    margin-right: auto;
  }

  .gNQUQW {
    font-size: 15px;
    font-weight: bold;
  }  


  .farm-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -90px;
  }

  .moving-img {
    margin-left: auto;
    margin-right: auto;
  }

  .desktop-icon.footer-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .staking-wrapper {
    margin-bottom: 220px;
  }
  .locking-wrapper {
    margin-top: 20px;

    margin-right: 24px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
  }

  .date-div {
    border-radius: 16px;
    border: 1px solid #372F47;
    max-width: 650px;
    width: 100%;
    background-color: 1d1d1d;
  }
  .date-center {
    display: flex;
    justify-content: center;
    margin-right: 24px;
    margin-left: 24px;
  }
  .me-btn {
    z-index: 999;
    position: absolute;
    width: 35px;
    right: 55px;
    top: 68%;
    background: none;
    color: white;
    border: 1px solid #0447FC;
    border-radius: 16px;
    transition: 0.2s all;
    padding: 3px 6px;
    display: flex;
    justify-content: center;
    text-align: center;
    &:hover {
      cursor: pointer;
      background: #0447FC;
    }
  }

  .max-btn {
    z-index: 999;
    position: absolute;
    right: 55px;
    top: 49%;
    background: none;
    color: white;
    border: 1px solid #0447FC;
    border-radius: 16px;
    transition: 0.2s all;
    padding: 3px 6px;
    display: flex;
    justify-content: center;
    text-align: center;
    &:hover {
      cursor: pointer;
      background: #0447FC;
    }
  }

  .locker-search {

    background: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    align-items: space-between;

  }
  .locker-link {
    max-width: 950px;
    width: 100%;
    margin-bottom: 20px;
    
  }

  .left-side {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    justify-content: center;
    margin: 20px 24px;
  }
  .full-content {
    background: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .black-div2 {
    background: #000
  }
  .black-div {
    background: #000;
    margin: 20px 24px;
  }

  .locker-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .locker-link-btn {
    border-radius: 16px;
    background: none;
    color: white;
    border: 1px solid #0447FC;
  }

  .locker-card-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .locker-card {


    margin: 12px 24px !important;
    padding: 12px 24px !important;
    background: radial-gradient(circle,#0447FC -150%,rgb(4,4,4) 100%);

  }

  .div-locker-text {
    background: #000;
    
    display: flex;
    flex-direction: column;
    max-width: 950px;
  }

  .locker-card-text {
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 20px;
    margin-top: 24px;
    background: black;
  }

  .pools-header {
    background: linear-gradient(90deg, #0447FC 0%, rgba(115, 204, 231, 0) 100%);
    border-radius: 24px;
    padding: 20px;
  }

  .staking-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 50px;
  }

  .header-mswap-logo {
    display: none;
  }

  .farm-icon {
    animation: rotation 20s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

   .app-body {

   }

  #swap-currency-input div:first-child, #swap-currency-output div:first-child, #add-liquidity-input-tokena div:first-child, #add-liquidity-input-tokenb div:first-child {
    background: #030918;
    border-radius: 15px;
  }

  #farms-table {
    background: none;
  }

  //table
  .gNZwVo, .capUXh {
    border-collapse: separate !important;
    border-spacing:0 20px;
  }

  //table row
  .zMuhg, .ebWzPE, .hhGvQm {
    background-color: #04e4d430;
    border-radius: 20px;
  }
  .zMuhg td:first-child, .ebWzPE td:first-child, .hhGvQm td:first-child { border-top-left-radius: 10px; border-bottom-left-radius: 10px;}
  .zMuhg td:last-child, .ebWzPE td:last-child, .hhGvQm td:last-child { border-top-right-radius: 10px; border-bottom-right-radius: 10px;}
  .zMuhg td:first-child, .ebWzPE td:first-child, .hhGvQm td:first-child { border-bottom-left-radius: 10px; }
  .zMuhg td:last-child, .ebWzPE td:last-child, .hhGvQm td:last-child { border-bottom-right-radius: 10px; }

  //more-info
  .kZkgYk, .UMNIl {
    background-color: #041132;
    margin-top: -25px;
    border: 2px solid #053853;
    border-radius: 0px 0px 20px 20px;
    border-top: none;
  }

  .jLKyew, .cRWjd {
    background-color: #041132;
    margin-top: -25px;
    border: 2px solid #053853;
    border-radius: 0px 0px 20px 20px;
    border-top: none;
  }

  .lddDwR {
    border-bottom: 1px solid #04E4D4;
  }

  .homepage-card {
    background: #000000;
  }

  .homepage-card div:first-child {
    background: #000000;
  }

  .header-icon {
    width: 150px;
  }

  @media screen and (max-width: 1330px) {

    .me-btn {
      top: 74%;
    }

    .max-btn {
      top: 54%;
    }
    .full-content {
      flex-direction: column;
    }

    .locker-text {
      flex-direction: column;
    }
    .locker-link {
      max-width: 750px;
    }
  }
  @media screen and (max-width: 680px) {

    .locker-card-div {
      flex-direction: row;
      justify-content: space-between;
    }


    .locker-search {
      flex-direction: column;
    }


  }

  @media screen and (max-width: 576px) {
    .staking-wrapper {
      flex-direction: column;
      margin-bottom: 420px;

      .staking-description {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      .staking-info {
        margin-right: 20px;
      }
    }

    .bridge-coming-soon {
      height: 2950px;
      position: relative;
    }

    .desktop-icon {
      display: initial !important;
    }

    .header-icon {
      width: 150px;
    }

    .footer-socials-icons {
      flex-direction: row;
    }

    .header-mswap-logo {
      display: none;
      width: 140px;
      height: 30px;
      position: absolute;
      top: -42px;
      left: 10px;
      z-index: 9999;
    }
    
    .farm-icon {
      margin-bottom: 0px;
    }
    .loading-indicator {
      padding-top: 31px;
    }
    .harvest-all {
      width: 100% !important;
      margin-bottom: 16px;
    }
    .header-bolt-price {
      img {
        display: none;
      }

      a::before {
        content: "Bolt";
        color: #873edf;
        font-weight: bold;
        text-transform: uppercase;
        margin-right: 8px;
      }
    }

    .harvest-card {
      display: flex;
      flex-direction: column;
      margin-top: 33px;
    }

    .tvl-string {
      order: 2;
      font-size: 18px;
      margin-right: auto;
      margin-left: 0px;
    }

    .my-holdings-string {
      top: 16px !important;
      left: 30px;
      font-size: 18px;      
    }

  }

  .swap-icon {
    margin-bottom: 15px;
  }

  .partners {    
    margin-top: 170px;
    .title {
      font-size: 40px;
      color: #F4EEFF;
      text-align: center;
      margin-bottom: 30px;
    }

    .partners-container {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .partner {
        transition: 0.5s all;
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
          filter: invert(0.3);
        }

        img {
          width: unset;
          height: 40px;
        }
      }
    }
  }
`

export default GlobalStyle
