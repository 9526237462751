export default {
  masterChef: {
    62621: '0x93321165AcC35263EDD18BdA7c2437F2a758F2ec',
    4: '0x3D75C0662cffC602857ac975226841c91fa6f645',
  },
  sousChef: {
    62621: '0x33081aE26b22aF6cCFdd2062e31f5624862Fc08F',
    4: '0xD38FA4CCC62c8c9aDbA3A7762DfE2fcCdCE0FaeA',
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
    62621: '',
    4: '',
  },
  multiCall: {
    62621: '0x7d8161672b6B741eeD97E934E1086c4Cac59bBa7',
    4: '0xb2F78219943d3d8640C30E54C73c79B6aa468400',
  },
  moonSpaceWorld: {
    62621: '0x4C659709750567Bd505Db6FAB163c107BeA9F161',
    4: '0x09c902001E888f5669Abe2A1A056FABD1c8240bD',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    4: '',
    62621: '0x1D4CC3AC82e9e32ddDA4387101Fb7A51fBf9D5d1',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    62621: '',
    4: '',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    62621: '',
    4: '',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
    62621: '',
    4: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    62621: '',
    4: '',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    62621: '',
    4: '',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    62621: '',
    4: '',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    62621: '',
    4: '',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
    81: '0x16e35F82c5b5496cf83BdDED78061baeaDD72e14',
    62621: '',
    4: '',
  },
  predictions: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
    62621: '',
    4: '',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
    62621: '',
    4: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
    62621: '',
    4: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
    62621: '',
    4: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    62621: '',
    4: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    62621: '',
    4: '',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    62621: '',
    4: '',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    62621: '',
    4: '',
  },
  migration: {
    62621: '0xf2350eBaa37d6E8ECAF00422e72cB7e5426a06E8',
    4: '0x47Bb63a6874ed40689970F7FB38eD57e5Bf960FD'
  },
  oldmoon: {
    62621: '0xB497c3E9D27Ba6b1fea9F1b941d8C79E66cfC9d6',
    4: '0x18c992a0ABB351d892aC8Ea322936Df20faeB1DC'
  },
  oldmswap: {
    62621: '0xb3fb48bf090bedff4f6f93ffb40221742e107db7',
    4: '0xD131F9A3eA6857e9ffbC82143269A407bAE32062'
  },
  moonswaptoken: {
    62621: '0x0FeD6177c230A6091E58D0081dcD31F88A256185',
    4: '0x4Ff9c4Ee099d39e30AaCD0fb0475eEF1b84C6B1A'
  },
  moonlocker: {
    62621: '0x8f367a53fb1eA5e9a08CEe00eAE27eD37f45188B',
    4: ''
  }
}
