import React from 'react'
import styled from 'styled-components'
import { Card } from 'mtv-mswap-uikitty'


export const BodyWrapper = styled(Card)`

  border-radius: 24px;
  max-width: 950px;
  width: 100%;
  z-index: 1;
  background: #0447FC;
  margin-bottom: 20px;
  @media only screen and (max-width: 1330px) {
    max-width: 750px;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper className="app-body">{children}</BodyWrapper>
}
