import React, { useEffect, useState } from 'react'
import { MaxUint256 } from '@ethersproject/constants'
import Select, { OptionProps } from 'components/Select/Select'
import { Heading, Flex, Image, Text, Button } from 'mtv-mswap-uikitty'
import styled from 'styled-components'
import SearchInput from 'components/SearchInput'
import Loading from 'components/Loading'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useToast from 'hooks/useToast'
import { useMigrationContract, useOldMoonContract, useOldMswapContract } from 'hooks/useContract'
import { ethers } from 'ethers'
import { getMigrationAddress, getOldMoonAddress, getOldMswapAddress } from 'utils/addressHelpers'
import { AppHeader, AppBody } from '../../components/App'
import Page from '../Page'

const ControlStretch = styled(Flex)`
 {
    flex: 1;    
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;    
  }
`

const Migration: React.FC = () => {

  // User wallet
  const { account } = useActiveWeb3React()

  // Contracts
  const migrationContract = useMigrationContract(account)
  const oldMoonContract = useOldMoonContract(account)
  const oldMswapContract = useOldMswapContract(account)

  // Addresses
  const migrationAddress = getMigrationAddress()
  const oldmoonAddress = getOldMoonAddress()
  const oldmswapAddress = getOldMswapAddress()

  // States for amount and contract selection
  const [amount, setAmount] = useState('0')
  const [contract, setContract] = useState('oldmswap')
  const [loading, setLoading] = useState(false)
  const { toastSuccess, toastError } = useToast()

  async function migrate() {
    const transferAmount = ethers.utils.parseEther(amount)
    let isApproved = ""
    setLoading(true)
    try {
      if (contract === 'oldmoon') {
        isApproved = await oldMoonContract.allowance(account, migrationAddress)
        if (isApproved.toString() === '0') {
         const approvetx = await oldMoonContract.approve(migrationAddress, MaxUint256,{  gasPrice: ethers.utils.parseUnits('3', 'gwei')})
         await approvetx.wait()
        }
        const tx = await migrationContract.migrate(oldmoonAddress, transferAmount,{  gasPrice: ethers.utils.parseUnits('3', 'gwei')})
        const receipt = await tx.wait()
        toastSuccess('Migrated!', 'You have succesfully migrated tokens')
      } else {
        isApproved = await oldMswapContract.allowance(account, migrationAddress)
        if (isApproved.toString() === '0') {
         const approvetx =  await oldMswapContract.approve(migrationAddress, MaxUint256,{  gasPrice: ethers.utils.parseUnits('3', 'gwei')})
         await approvetx.wait()
        }
        const tx = await migrationContract.migrate(oldmswapAddress, transferAmount,{  gasPrice: ethers.utils.parseUnits('3', 'gwei')})
        const receipt = await tx.wait()
        toastSuccess('Migrated!', 'You have succesfully migrated tokens')
      }
    } catch (error) {
      toastError('Error', 'Please try again. Confirm the transaction and make sure you are paying enough gas!')
    }
    setLoading(false)
  }

  // Handle change events
  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value)
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setContract(option.value)
  }

  return (
    <Page>
      <img className="farm-icon swap-icon" src="/images/boltswap-nav-migration.png" alt="" width="200px" height="200px" />
      <AppBody>
        <div className="migration-header">
          <AppHeader title='Migration' subtitle='Get new MOON token' noConfig />
        </div>
        <ControlStretch>
          <Select
            className="migration-dropdown"
            options={[
              {
                label: "MSWAP",
                value: 'oldmswap',
              },
              {
                label: "MOON",
                value: 'oldmoon',
              }
            ]}
            onOptionChange={handleSortOptionChange}
          />
        </ControlStretch>
        <SearchInput className="migration-amount" onChange={handleChangeQuery} placeholder="Enter amount" />
        {loading ? (
          <div className="loading-indicator migration">
            <span>Migrating</span>
            <div style={{ marginTop: "28px", marginLeft: "10px" }}>
              <Loading />
            </div>
          </div>
        ) : (
          ""
        )}
        <Button onClick={migrate} className="migration-button">Migrate </Button>
      </AppBody>
    </Page>
  )
}

export default Migration
