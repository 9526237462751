import MoonSwapPairAbi from 'config/abi/MoonSwapPair.json'
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

const provider = new ethers.providers.JsonRpcProvider("https://rpc.mtv.ac/")

export async function getMtvPrice() {
    const moonswapPair = new ethers.Contract('0x6184e61aa610efd4a37ec5e6b51f4cc5042e8fa0', MoonSwapPairAbi, provider)
    const reserves = await moonswapPair.getReserves()
    const reserve0 = reserves.reserve0._hex
    const reserve0Hex = new BigNumber(reserve0).c[0]

    const reserve1 = reserves.reserve1._hex
    const reserve1Hex = new BigNumber(reserve1).c[0]

    const finalPrice = reserve0Hex / reserve1Hex

    return finalPrice
}