import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   lpSymbol: 'MOON',
  //   lpAddresses: {
  //     62621: '0x8089bC747bb9a096C2c8D5f6459136bc55D2681A',
  //     4: '0x4Ff9c4Ee099d39e30AaCD0fb0475eEF1b84C6B1A',
  //   },
  //   token: serializedTokens.moon,
  //   quoteToken: serializedTokens.moon,
  // },
  {
    pid: 1,
    lpSymbol: 'MOON-MTV LP',
    lpAddresses: {
      62621: '0x57e962Ac51b5b197aA0f11e8A0a42172A706a70C',
      4: '0xe289B6Ed23F6332e51AA98e5340a3443d0B7c041',
    },
    token: serializedTokens.moon,
    quoteToken: serializedTokens.wmtv,
  },
  {
    pid: 2,
    lpSymbol: 'MTV-bUSD LP',
    lpAddresses: {
      62621: '0x6184e61aa610efd4a37ec5e6b51f4cc5042e8fa0',
      4: '0x084ffd2bcae768f8229699997570e6d30c2de165',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wmtv,
  },
  {
    pid: 3,
    lpSymbol: 'MOON-bUSD LP',
    lpAddresses: {
      62621: '0xbde12b1a40d6e1b3f4558fbaade2a60961e713e6',
      4: '0x60F99c5D8809C4bC8c20d101767401b8E276c035',
    },
    token: serializedTokens.moon,
    quoteToken: serializedTokens.busd,
  },
  // {
  //   pid: 4,
  //   lpSymbol: 'ETH-MOVR',
  //   lpAddresses: {
  //     62621: '0xC800bad443504f9BE54948e85c6B9E85Fff5C8e2',
  //     4: '0x4582E5c99a8c24383df9Bd6A471D7Bd1c126cBd7',
  //   },
  //   token: serializedTokens.weth,
  //   quoteToken: serializedTokens.wmtv,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'BNB-BUSD',
  //   lpAddresses: {
  //     62621: '0xDD0082D71A070bb2CaCDc15f0B70b537a2e1Fd25',
  //     4: '0x6C0FA2ecFbF26dFF3Ef3E3EF9fcaAD4ED20CB66c',
  //   },
  //   token: serializedTokens.bnbNotSdn,
  //   quoteToken: serializedTokens.busd,
  // },
  // {
  //   pid: 6,
  //   lpSymbol: 'ETH-USDC',
  //   lpAddresses: {
  //     62621: '0x4A2Dd3B45b5A8bf202814163C766d8648F1751E2',
  //     4: '0xCebf759096C6430a3c44D28f21E6054E6086ebB6',
  //   },
  //   token: serializedTokens.weth,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 7,
  //   lpSymbol: 'USDT-USDC',
  //   lpAddresses: {
  //     62621: '0xa2354DDD6DC06F72f658EA9993F171264ad6ac63',
  //     4: '0x30e62C57B2D22d42709FcebE8602e4B8D1a1B95f',
  //   },
  //   token: serializedTokens.usdt,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 8,
  //   lpSymbol: 'BUSD-USDC',
  //   lpAddresses: {
  //     62621: '0x6BE4233C4C6E654446e74945C51a34F5F0C57Cdf',
  //     4: '0x0D10b4aBa3Ed079D1c2F9b0E1806705E22B7B47d',
  //   },
  //   token: serializedTokens.usdc,
  //   quoteToken: serializedTokens.busd,
  // },
  // {
  //   pid: 9,
  //   lpSymbol: 'DAI-USDC',
  //   lpAddresses: {
  //     62621: '0xb14a26e11c06E07eb50E143b6DC089232dD5d692',
  //     4: '0x2370Aca069b2303f8270bCF4eA4Ed4C574873DB8',
  //   },
  //   token: serializedTokens.dai,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 10,
  //   lpSymbol: 'MAI-USDC',
  //   lpAddresses: {
  //     62621: '0x99F205643945CECCA37FC88d0e0B59C2Cde731AF',
  //     4: '0xc5291310C705691D300310F0f0F564dAA444266E',
  //   },
  //   token: serializedTokens.mai,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 11,
  //   lpSymbol: 'MIM-USDC',
  //   lpAddresses: {
  //     62621: '0xC36CAe6D0C3e13dF5DCed53CA0C6C8F681cDe3AD',
  //     4: '0x948881053AB31b5Ca9e74D4817c68Bb000653E2B',
  //   },
  //   token: serializedTokens.mim,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 12,
  //   lpSymbol: 'SDN-MOVR',
  //   lpAddresses: {
  //     62621: '0xD43C0C69EC4004Aa54AE3d0eF565C897914b7d89',
  //     4: '0x2E6AD2B2a0d2E05E3eA529B9645F58A25bB289B1',
  //   },
  //   token: serializedTokens.sdn,
  //   quoteToken: serializedTokens.wmtv,
  // },
  // {
  //   pid: 13,
  //   lpSymbol: 'TOOLS-MOVR',
  //   lpAddresses: {
  //     62621: '0xFd44d8cb4947CFe91B3D1DEdED99faFBBeBd6d4b',
  //     4: '0x62b535CbbeF65C042e9C7870Bb909dE88a9B4345',
  //   },
  //   token: serializedTokens.tools,
  //   quoteToken: serializedTokens.wmtv,
  // },
  // {
  //   pid: 14,
  //   lpSymbol: 'FTM-MOVR',
  //   lpAddresses: {
  //     62621: '0x9f7669e93084f0562e19C97B3d2c6f7AaE210688',
  //     4: '0x197e849294560107CF1Bdf4020Db0b9F8A13E8Be',
  //   },
  //   token: serializedTokens.ftm,
  //   quoteToken: serializedTokens.wmtv,
  // },
  // {
  //   pid: 15,
  //   lpSymbol: 'MATIC-MOVR',
  //   lpAddresses: {
  //     62621: '0x58FAbe3Ad054bF589EACA75F0aad36F43C4c0A8c',
  //     4: '0xa52526BFA6fbAA36e9A5B28f9cB17A888E5Ae86A',
  //   },
  //   token: serializedTokens.matic,
  //   quoteToken: serializedTokens.wmtv,
  // },
  // {
  //   pid: 16,
  //   lpSymbol: 'AVAX-MOVR',
  //   lpAddresses: {
  //     62621: '0x8B73De2C4d807F10E14a91e7D93b55c575749A30',
  //     4: '0x68595c6cbb68BC9Ea67c675Cb95be505f8F8E343',
  //   },
  //   token: serializedTokens.avax,
  //   quoteToken: serializedTokens.wmtv,
  // },
  // {
  //   pid: 17,
  //   lpSymbol: 'RELAY-MOVR',
  //   lpAddresses: {
  //     62621: '0xd34dCE14Ea13b93CF81Cd99dbC40DF50B1804fB3',
  //     4: '0xd350BA53FD0E68223E5Fd9E4Dec5A0439bd1e22a',
  //   },
  //   token: serializedTokens.relay,
  //   quoteToken: serializedTokens.wmtv,
  // },
  // {
  //   pid: 18,
  //   lpSymbol: 'BEANS-MOVR',
  //   lpAddresses: {
  //     62621: '0xd8ac27BEeD2e952BD49F7547ec8A5dc580E9B427',
  //     4: '0x24A0D25236bDFc73336b6e3E08cA3a2A87a8D310',
  //   },
  //   token: serializedTokens.beans,
  //   quoteToken: serializedTokens.wmtv,
  // },
  // {
  //   pid: 19,
  //   lpSymbol: 'RIVRDOGE-MOVR',
  //   lpAddresses: {
  //     62621: '0xc81A9097959c07e99d1b75638787463B4B30b1b3',
  //     4: '0xb7EecaAb1df9AD7d686489EB44d4777bAAF2a838',
  //   },
  //   token: serializedTokens.rivrdoge,
  //   quoteToken: serializedTokens.wmtv,
  // },

]

export default farms
