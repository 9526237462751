import React, { useState, useMemo } from 'react'
import { Input, Button } from 'mtv-mswap-uikitty'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

/* eslint-disable */

const StyledInput = styled(Input)`
  border-radius: 16px;
  margin-left: auto;
  background: #030918;
  height: 60px;
  text-overflow: ellipsis;
  padding-right: 35px;
`

const InputWrapper = styled.div`
  position: relative;

  
  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
    
  }
`

const ButtonStyled = styled.button`
z-index: 999;
position: absolute;
right: 10px;
top: 30%;
background: none;
color: white;
border: 1px solid #0447FC;
border-radius: 16px;
transition: 0.2s all;
padding: 3px 6px;
display: flex;
justify-content: center;
text-align: center;
&:hover {
  cursor: pointer;
  background: #0447FC;
}
`

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  className?: string
  input?: string
}

const SearchInput: React.FC<Props> = ({ onChange: onChangeCallback, placeholder = 'Search', className = '' }) => {
  const [searchText, setSearchText] = useState('')

  const { t } = useTranslation()

  const debouncedOnChange = useMemo(
    () => debounce((e: React.ChangeEvent<HTMLInputElement>) => onChangeCallback(e), 500),
    [onChangeCallback],
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)

    debouncedOnChange(e)
  }



  return (
    <InputWrapper className={className} >
      <StyledInput value={searchText} onChange={onChange} placeholder={t(placeholder)} />
    </InputWrapper >
  )
}

export default SearchInput