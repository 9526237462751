import { MenuItemsType, DropdownMenuItemType } from 'mtv-mswap-uikitty'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Swap'),
    icon: 'boltswap-nav-swap',
    href: '/swap',
    showItemsOnMobile: false,
    items: [
    ],
  },
  {
    label: t('Liquidity'),
    href: '/liquidity',
    icon: 'boltswap-nav-liquidity',
    showItemsOnMobile: false,
    items: [
    ],
  },
  {
    label: t('Farms'),
    href: '/farms',
    icon: 'boltswap-nav-farms',
    showItemsOnMobile: false,
    items: [
    ],
  },
  // {
  //   label: t('Migrate'),
  //   href: '/migration',
  //   icon: 'boltswap-nav-migration',
  //   showItemsOnMobile: false,
  //   items: [
  //   ],
  // },
  {
    label: t('Stake MOON'),
    href: '/pools',
    icon: 'boltswap-nav-xmoon',
    showItemsOnMobile: false,
    items: [
    ],
  },
  {
    label: t('Locker'),
    href: '/locker',
    icon: 'boltswap-nav-locker',
    showItemsOnMobile: false,
    items: [
    ],
  },
  // {
  //   label: t('Stats'),
  //   href: '/stats',
  //   icon: 'moonswap-nav-stats',
  //   showItemsOnMobile: false,
  //   items: [
  //     // {
  //     //   label: t('Stats'),
  //     //   href: 'https://info.moonswap.tech/home',
  //     //   type: DropdownMenuItemType.EXTERNAL_LINK,
  //     // },
  //   ],
  // }, 
]

export default config
