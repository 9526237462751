/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react'
import AppBody from './Components/AppyBody'
import { Flex, Text, Button, Heading } from 'mtv-mswap-uikitty'
import { isAddress } from 'utils'
import Datetime from 'react-datetime'

import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useApproveCallback, ApprovalState } from 'hooks/useApproveCallback'
import { tryParseAmount } from 'state/swap/hooks'
import { useCurrency } from 'hooks/Tokens'
import * as moment from 'moment'
import "./react-datetime.css";
import { ethers } from 'ethers'
import useToast from 'hooks/useToast'
import { Input as NumericalInput } from '../../components/CurrencyInputPanel/NumericalInput'
import { useMoonLockerContract } from 'hooks/useContract'
import { getMoonLockerAddress } from 'utils/addressHelpers'
import Lockersearch from './lockersearch'
import Page from '../Page'

import { useCurrencyBalance } from 'state/wallet/hooks'



/* eslint-disable */

const Locker: React.FC = () => {
    // User wallet
    const { account } = useActiveWeb3React()

    // Contract inputs
    const [tokenAddress, setTokenAddress] = useState('')
    const [amount, setAmount] = useState('')
    const [withdrawAddress, setWithdrawAddress] = useState('')
    const [unlockDate, setUnlockDate] = useState(moment.default())
    const [lockerComponent, setlockerComponent] = useState(false)
    const [firstBtn, setfirstBtn] = useState('#0447FC')
    const [secondBtn, setsecondBtn] = useState('none')


    //states
    const [pendingTx, setPendingTx] = useState(false)

    // Contracts
    const moonLockContract = useMoonLockerContract(account)


    // Address
    const lockerAddress = getMoonLockerAddress()

    const { toastSuccess, toastError } = useToast()

    //approve
    const assetToken = useCurrency(tokenAddress) || undefined
    const typedAmount = tryParseAmount(amount, assetToken)

    const [approvalState, approve] = useApproveCallback(typedAmount, lockerAddress)

    // check if user has gone through an approval state, used to show two step buttons, reset on token change
    const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

    const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, assetToken ?? undefined)

    useEffect(() => {
        if (approvalState === ApprovalState.PENDING) {
            setApprovalSubmitted(true)
        }
    }, [approvalState, approvalSubmitted])

    const handleApprove = useCallback(async () => {
        await approve()
        setApprovalSubmitted(true)
    }, [approve])



    const errorMessage = !isAddress(tokenAddress)
        ? 'Invalid token'
        : !isAddress(withdrawAddress)
            ? 'Invalid withdrawer'
            : isNaN(parseFloat(amount)) || parseFloat(amount) == 0
                ? 'Invalid amount'
                : moment.isDate(unlockDate) || moment.default(unlockDate).isBefore(new Date())
                    ? 'Invalid unlock date'
                    : ''

    const allinfoSubmitted = errorMessage == ''

    async function lockTokens() {
        if (allinfoSubmitted) {
            setPendingTx(true)

            const lockAmount = ethers.utils.parseEther(amount)
            try {
                const tx = await moonLockContract.lockTokens(
                    tokenAddress,
                    withdrawAddress,
                    lockAmount,
                    moment.default(unlockDate).unix().toString(), { value: ethers.utils.parseEther("2000") })
                const waittx = await tx.wait()
                toastSuccess(`You have successfully locked your tokens until ${unlockDate}`)
            }
            catch (err) {
                toastError('Failed')
            } finally {
                setPendingTx(false)
            }
        }
    }


    var valid = function (current) {
        return current.isAfter(moment.default(unlockDate).subtract(1, 'day'))
    }

    /* SEARCH AND WITHDRAW TOKENS */
    const today = Date()
    const tommorow = new Date(today)
    tommorow.setDate(tommorow.getDate() + 1)


    return (
        <Page>
            <div className='locker-link'>
                <Button
                    style={{ background: firstBtn, marginRight: '6px' }}
                    className='locker-link-btn' onClick={() => {
                        setlockerComponent(false);
                        setfirstBtn('#0447FC');
                        setsecondBtn('none')
                    }}>Locker</Button>
                <Button
                    style={{ background: secondBtn }}
                    className='locker-link-btn' onClick={() => {
                        setlockerComponent(true)
                        setfirstBtn('none');
                        setsecondBtn('#0447FC')
                    }}>Search lockers</Button>

            </div>
            {lockerComponent === false &&
                <>
                    <AppBody>

                        <div className="div-locker-text">
                            <div className='black-div'>
                                <Heading className='heading-style'>How to use</Heading>
                                <Text style={{ marginBottom: "3px" }}>Input your unlock date, liquidity pair address, amount of tokens to lock and the withdrawers address</Text>
                                <Heading style={{ marginBottom: "3px" }}>Fees</Heading>
                                <Text style={{ marginBottom: "3px" }}>2000 MTV to lock</Text>
                                <Heading style={{ marginBottom: "3px" }}>Warning</Heading>
                                <Text style={{ marginBottom: "3px" }}>You will not be able to withdraw your tokens before the unlock time.</Text>
                                <Text style={{ marginBottom: "3px" }}>Locker contract address: 0x8f367a53fb1eA5e9a08CEe00eAE27eD37f45188B</Text>
                                <Text style={{ marginBottom: "3px" }}>DYOR before using</Text>
                            </div>
                        </div>
                    </AppBody>
                    <AppBody>
                        {/* <AppHeader title='Locker' subtitle='Liquidity locker' noConfig /> */}
                        <div className="full-content">
                            <div className='left-side'>
                                <div className="date-center">
                                    <div className="locker-text">
                                        <Text>Withdraw date</Text>
                                        <div className="date-div">
                                            <Datetime
                                                initialValue={tommorow}
                                                initialViewDate={tommorow}
                                                value={unlockDate}
                                                utc={true}
                                                closeOnSelect={true}
                                                isValidDate={valid}
                                                onChange={(e) => setUnlockDate(moment.default(e))}
                                                inputProps={{
                                                    className: "date-time"
                                                }} />
                                        </div>
                                    </div>
                                </div>

                                <Flex className="locking-wrapper" style={{ justifyContent: 'center' }}>
                                    <div className="locker-text">
                                        <Text>Token address</Text>
                                        {/* <input className='search-locker' /> */}
                                        <input className="search-locker" placeholder="LP-token address" onChange={(e) => setTokenAddress(e.target.value)} />
                                    </div>
                                    <div className="locker-text">
                                        <Text>Amount</Text>
                                        {/* <input className='search-locker' /> */}
                                        <NumericalInput className='search-locker' value={amount} onUserInput={(val) => setAmount(val)} />
                                        {/* <SearchInput className="search-locker" onChange={handleDepositAmount} placeholder="0.00 " /> */}
                                        {assetToken && selectedCurrencyBalance ? (
                                            <div className='max-btn'
                                                onClick={() => setAmount(selectedCurrencyBalance.toFixed())}>Max</div>
                                        ) : null
                                        }
                                    </div>
                                    <div className="locker-text">
                                        <Text>Withdrawer</Text>
                                        {/* <input className='search-locker' placeholder="Withdraw address" /> */}
                                        <input className="search-locker" onChange={(e) => setWithdrawAddress(e.target.value)} value={withdrawAddress} placeholder="Withdraw address " />
                                        <div className='me-btn' onClick={() => setWithdrawAddress(account)}>Me</div>
                                    </div>

                                    <div className="locker-text">
                                        <Text></Text>
                                        {approvalState !== ApprovalState.APPROVED && (
                                            <Button
                                                className="lock-button"
                                                onClick={handleApprove}
                                                disabled={approvalState !== ApprovalState.NOT_APPROVED ||
                                                    approvalSubmitted ||
                                                    !allinfoSubmitted}
                                            >
                                                {approvalState === ApprovalState.PENDING ? (
                                                    <div className={'p-2'}>
                                                        <span>loading</span>
                                                    </div>
                                                ) :
                                                    (
                                                        <span>Approve Token</span>
                                                    )}
                                            </Button>
                                        )}
                                        {approvalState === ApprovalState.APPROVED && (
                                            <Button
                                                className="lock-button"
                                                onClick={lockTokens}
                                                style={{
                                                    width: '100%',
                                                }}
                                                disabled={approvalState !== ApprovalState.APPROVED || !allinfoSubmitted || pendingTx}
                                            >
                                                {pendingTx ? (
                                                    <div className={'p-2'}>
                                                        Locking
                                                    </div>
                                                ) : (
                                                    <span> {errorMessage == '' ? 'Lock' : errorMessage}</span>
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                </Flex>
                            </div>
                            {/* <Button onClick={() => getMaxTokenBalance()} >Token balance test</Button> */}
                        </div>
                    </AppBody></>
            }
            {
                lockerComponent === true &&
                <Lockersearch />
            }
        </Page >
    )
}


export default Locker