import BigNumber from 'bignumber.js'
import { useMoonSpaceWorld, useMoonSwapContract } from 'hooks/useContract'
import { getMoonspaceworld, getMoonSwapToken } from 'utils/addressHelpers'
import { useBusdPriceFromPid } from 'state/farms/hooks'
import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'

export const useMoonTVL = () => {
  const [data, setData] = useState<number | null>(null)
  const moonSwapContract = useMoonSwapContract()
  const moonSpaceWorldAddress = getMoonspaceworld()
  const moonPrice = useBusdPriceFromPid(1);

  useEffect(() => {
    const fetchData = async () => {
      const totalMoonBalance = await moonSwapContract.balanceOf(moonSpaceWorldAddress);
      const converted = new BigNumber(ethers.utils.formatEther(totalMoonBalance.toString()))
      const TVL = converted.multipliedBy(moonPrice)
      setData(TVL.toNumber())
    }

    fetchData()

  }, [moonPrice, moonSpaceWorldAddress, moonSwapContract])

  return data;
}


export const useStakedMoon = () => {
  const [stakedMoon, setStakedMoon] = useState<BigNumber | null>(null)
  const { account } = useWeb3React()
  const moonSpaceWorldContract = useMoonSpaceWorld()

  useEffect(() => {
    const fetchStakedMoon = async () => {
      const userBalance = await moonSpaceWorldContract.MOONBalance(account)
      const coverted = new BigNumber(ethers.utils.formatEther(userBalance.toString()))
      setStakedMoon(coverted)
    }
    if (account) {
      fetchStakedMoon()
    }

  }, [account, moonSpaceWorldContract])

  return stakedMoon;
}

export const useMoonUserBalance = () => {
  const [userMoonBalance, setMoonUserBalance] = useState<BigNumber | null>(null)
  const { account } = useWeb3React()
  const moonSwapContract = useMoonSwapContract()

  useEffect(() => {
    const fetchMoonUserBalance = async () => {
      const totalUserMoonBalance = await moonSwapContract.balanceOf(account);
      const covertedBalance = new BigNumber(ethers.utils.formatEther(totalUserMoonBalance.toString()))
      setMoonUserBalance(covertedBalance)
    }
    if (account) {
      fetchMoonUserBalance()
    }

  }, [moonSwapContract, account])

  return userMoonBalance;
}

export const useXMoonUserBalance = () => {
  const [userXMoonBalance, setXMoonUserBalance] = useState<BigNumber | null>(null)
  const { account } = useWeb3React()
  const moonSpaceWorldContract = useMoonSpaceWorld()

  useEffect(() => {
    const fetchMoonUserBalance = async () => {
      const totalUserXMoonBalance = await moonSpaceWorldContract.balanceOf(account);
      const covertedBalance = new BigNumber(ethers.utils.formatEther(totalUserXMoonBalance.toString()))
      setXMoonUserBalance(covertedBalance)
    }
    if (account) {
      fetchMoonUserBalance()
    }

  }, [moonSpaceWorldContract, account])

  return userXMoonBalance;
}


export const useMoonAPR = () => {
  const [apr, setAPR] = useState<number | null>(null)
  const moonSwapContract = useMoonSwapContract()
  const moonSpaceWorldAddress = getMoonspaceworld()

  useEffect(() => {
    const fetchAPR = async () => {
      const totalMoonBalance = await moonSwapContract.balanceOf(moonSpaceWorldAddress);
      const converted = new BigNumber(ethers.utils.formatEther(totalMoonBalance.toString()))
      setAPR((15768 / converted.toNumber()) * 100)
    }
    fetchAPR()
  }, [moonSpaceWorldAddress, moonSwapContract])

  return apr;
}

export const useMoonXMoonRatio = () => {
  const [ratio, setRatio] = useState<number | null>(null)
  const moonSpaceWorldContract = useMoonSpaceWorld()

  useEffect(() => {
    const fetchRatio = async () => {
      const ratioAmount = ethers.utils.parseEther("1")
      const ratioContract = await moonSpaceWorldContract.xMOONForMOON(ratioAmount)
      const coverted = new BigNumber(ethers.utils.formatEther(ratioContract.toString()))
      setRatio(coverted.toNumber())
    }
    fetchRatio()


  }, [moonSpaceWorldContract])

  return ratio;
}

export const useIsApproved = () => {
  const [approved, setApproved] = useState<string | null>(null)
  const { account } = useWeb3React()
  const moonSwapContract = useMoonSwapContract()
  const moonSpaceWorldAddress = getMoonspaceworld()

  useEffect(() => {
    const fetchApproval = async () => {
      const checkFor = await moonSwapContract.allowance(account, moonSpaceWorldAddress);
      setApproved(checkFor.toString())
    }
    if (account) {
      fetchApproval()
    }
  }, [moonSpaceWorldAddress, account, moonSwapContract])

  return approved;
}
