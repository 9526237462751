import { ChainId, Token } from 'mtv-mswap-sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wmtv: new Token(
    MAINNET,
    '0x4c4A5d20f1ee40eaacB6A7787d20D16b7997363B', // change
    18,
    'WMTV',
    'Wrapped MTV',
    'https://www.binance.com/',
  ),
  weth: new Token(
    MAINNET,
    '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C', // eth
    18,
    'WETH',
    'Wrapped Ether',
    'https://www.ethereum.com/',
  ),
  // bnb here points to the wmtv contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(MAINNET, '0x4c4A5d20f1ee40eaacB6A7787d20D16b7997363B', 18, 'BNB', 'BNB', 'https://www.binance.com/'), // change
  bnbNotSdn: new Token(MAINNET, '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c', 18, 'BNB', 'BNB', 'https://www.binance.com/'),
  moon: new Token(
    MAINNET,
    '0x0FeD6177c230A6091E58D0081dcD31F88A256185', // change me
    18,
    'MOON',
    'MOON token',
    'https://mtv.moonswap.tech',
  ),
  xmoon: new Token(
    MAINNET,
    '0x4C659709750567Bd505Db6FAB163c107BeA9F161',
    18,
    'XMOON',
    'XMOON token',
    'https://mtv.moonswap.tech',
  ),
  busd: new Token(
    MAINNET,
    '0x338a6997011c4eee53822837c4f95bbba3a0a7f5',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  dai: new Token(
    MAINNET,
    '0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844',
    18,
    'DAI',
    'Dai Stablecoin',
    'https://www.makerdao.com/',
  ),
  usdt: new Token(
    MAINNET,
    '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
    6,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  ust: new Token(
    MAINNET,
    '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',  // does not exist remove from price hopping to remove here
    18,
    'UST',
    'Wrapped UST Token',
    'https://mirror.finance/',
  ),
  eth: new Token(
    MAINNET,
    '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C', // same as weth
    18,
    'ETH',
    'Binance-Peg Ethereum Token',
    'https://ethereum.org/en/',
  ),
  usdc: new Token(
    MAINNET,
    '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    6,
    'USDC',
    'Binance-Peg USD Coin',
    'https://www.centre.io/usdc',
  ),
  // sdn: new Token(
  //   MAINNET,
  //   '0x22bBe2E8ea84BBCD32d4d48d499B0d3E1a8e3De6',
  //   18,
  //   'SDN',
  //   'Shiden Token',
  //   'https://www.bakeryswap.org/',
  // ),

  // matic: new Token(
  //   MAINNET,
  //   '0x682F81e57EAa716504090C3ECBa8595fB54561D8',
  //   18,
  //   'MATIC',
  //   'Polygon matic',
  //   'https://www.bakeryswap.org/',
  // ),
  // avax: new Token(
  //   MAINNET,
  //   '0x14a0243C333A5b238143068dC3A7323Ba4C30ECB',
  //   18,
  //   'AVAX',
  //   'Avalanche Token',
  //   'https://www.bakeryswap.org/',
  // ),
  // ftm: new Token(
  //   MAINNET,
  //   '0xaD12daB5959f30b9fF3c2d6709f53C335dC39908',
  //   18,
  //   'FTM',
  //   'Fantom Token',
  //   'https://www.bakeryswap.org/',
  // ),
  // relay: new Token(
  //   MAINNET,
  //   '0xAd7F1844696652ddA7959a49063BfFccafafEfe7',
  //   18,
  //   'RELAY',
  //   'Relay Token',
  //   'https://www.bakeryswap.org/',
  // ),
  // mim: new Token(
  //   MAINNET,
  //   '0x0caE51e1032e8461f4806e26332c030E34De3aDb',
  //   18,
  //   'MIM',
  //   'Magic internet money',
  //   'https://www.bakeryswap.org/',
  // ),
  // mai: new Token(
  //   MAINNET,
  //   '0x7f5a79576620C046a293F54FFCdbd8f2468174F1',
  //   18,
  //   'MAI',
  //   'Mi matic',
  //   'https://www.bakeryswap.org/',
  // ),
  // tools: new Token(
  //   MAINNET,
  //   '0x5853ccBDc428d5fC9F8C1d3599B252C88477b460',
  //   18,
  //   'TOOLS',
  //   'River Tools',
  //   'www.bakeryswap.org'
  // ),
  // rivrdoge: new Token(
  //   MAINNET,
  //   '0x5D4360f1Be94bD6f182F09cFE5EF9832e65EB1ac',
  //   18,
  //   'RIVRDOGE',
  //   'River Tools',
  //   'www.bakeryswap.org'
  // ),
  // beans: new Token(
  //   MAINNET,
  //   '0xC2392DD3e3fED2c8Ed9f7f0bDf6026fcd1348453',
  //   18,
  //   'BEANS',
  //   'River Tools',
  //   'www.bakeryswap.org'
  // ),
  /* These 2 are required because of some warning on the exchange never used */
  safemoon: new Token(
    MAINNET,
    '0xc2392dd3e3fed2c8ed9f7f0bdf6026fcd1348353',
    18,
    'safemoon',
    'blabla',
    'www.bakeryswap.org'
  ),
  bondly: new Token(
    MAINNET,
    '0xc2392dd3e3fed2c8ed9f7f0bdf6026fcd1346453',
    18,
    'TOOLS',
    'River Tools',
    'www.bakeryswap.org'
  )
}

export const testnetTokens = {
  wmtv: new Token(
    TESTNET,
    '0x2110D874e9B7c3eCb4908cce5c721A436507dA7e',
    18,
    'WMTV',
    'Wrapped MTV',
    'https://www.binance.com/',
  ),
  weth: new Token(
    TESTNET,
    '0x40234BeC87d4b6564c528f613677c694921E1633',
    18,
    'WETH',
    'Wrapped Ether',
    'https://www.ethereum.com/',
  ),
  moon: new Token(
    TESTNET,
    '0xBD8aE108e338CbF8b4fa75BFD0b7d924260aB811',
    18,
    'MOON',
    'MOON token',
    'https://mtv.moonswap.tech',
  ),
  xmoon: new Token(
    TESTNET,
    '0x09c902001E888f5669Abe2A1A056FABD1c8240bD',
    18,
    'xMOON',
    'xMOON token',
    'https://mtv.moonswap.tech',
  ),
  // matic: new Token(
  //   TESTNET,
  //   '0x70750DaECf7b4FC8859576Ed751e32FC7a0E62b3',
  //   18,
  //   'MATIC',
  //   'Polygon matic',
  //   'https://www.bakeryswap.org/',
  // ),
  busd: new Token(
    TESTNET,
    '0xe921D681C810D9278B192bDe4c34410FF33609C5',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  // sdn: new Token(
  //   TESTNET,
  //   '0x75f127135d4E88a65f2742BaCeD0255d2a4c2230',
  //   18,
  //   'MOVR',
  //   'MoonRiver Token',
  //   'https://www.bakeryswap.org/',
  // ),
  bnb: new Token(
    TESTNET,
    '0x1cBF6CF76D71C698cE663b871315562d848397bF',
    18,
    'BNB',
    'Binance coin',
    'https://www.bakeryswap.org/',
  ),
  // avax: new Token(
  //   TESTNET,
  //   '0xDA7e944B9f20dF38c6D1700CA04bbDc507575193',
  //   18,
  //   'AVAX',
  //   'Avalanche Token',
  //   'https://www.bakeryswap.org/',
  // ),
  // ftm: new Token(
  //   TESTNET,
  //   '0x95c2aA2e906af27588B81829fa0b3a2A4e9F3541',
  //   18,
  //   'FTM',
  //   'Fantom Token',
  //   'https://www.bakeryswap.org/',
  // ),
  // relay: new Token(
  //   TESTNET,
  //   '0x18C7fc51C629072dDd161622b865370575E5B428',
  //   18,
  //   'RELAY',
  //   'Relay Token',
  //   'https://www.bakeryswap.org/',
  // ),
  usdc: new Token(
    TESTNET,
    '0x8d32353C8eCb8B76DDa431fB0e99650883A8b0D1',
    18,
    'USDC',
    'USD Coin',
    'https://www.bakeryswap.org/',
  ),
  // mim: new Token(
  //   TESTNET,
  //   '0x55D4570847eF41f4eb618E11d7AF90DA349319e2',
  //   18,
  //   'MIM',
  //   'MIM Token',
  //   'https://www.bakeryswap.org/',
  // ),
  // mai: new Token(
  //   TESTNET,
  //   '0x0878ab01357110ad9E703993C87914901C07ACD1',
  //   18,
  //   'MAI',
  //   'MAI Token',
  //   'https://www.bakeryswap.org/',
  // ),
  // dai: new Token(
  //   TESTNET,
  //   '0x88155AD03775c3b988BC16712aBaBcf843715d9d',
  //   18,
  //   'DAI',
  //   'DAI Token',
  //   'https://www.bakeryswap.org/',
  // ),
  usdt: new Token(
    TESTNET,
    '0x53668245ed04783B2B4B70ED6FA27ab7419e9781',
    18,
    'USDT',
    'Tether USDT',
    'https://www.bakeryswap.org/',
  ),
  // tools: new Token(
  //   TESTNET,
  //   '0xcf2972Fc0E6f2DE3B8246B77C837DCC5F30B1f20',
  //   18,
  //   'TOOLS',
  //   'River Tools',
  //   'www.bakeryswap.org'
  // ),
  // rivrdoge: new Token(
  //   TESTNET,
  //   '0xec6A905CdC2b435feEaFa7f8dde94A106e6BE451',
  //   18,
  //   'RIVRDOGE',
  //   'River Tools',
  //   'www.bakeryswap.org'
  // ),
  // beans: new Token(
  //   TESTNET,
  //   '0x1e654e40C24CAfc0Ddff003baACB96ba27C4531F',
  //   18,
  //   'BEANS',
  //   'Moon beans',
  //   'www.bakeryswap.org'
  // )
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
