import React from 'react'

const Bridge: React.FC = () => {
  return (
    <>
      <div className="bridge-coming-soon">
      <iframe title="info" src="https://info.moonswap.tech/home" style={{width: '100%', height: '100%'}} scrolling="no"/>
      </div>
    </>
  )
}

export default Bridge
